.page-error-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: black;

  h1 {
    margin: 0;
    color: white;
    font-size: 2em;
    font-family: Arial, sans-serif;

    span {
      font-size: 4em;
    }
  }
}
