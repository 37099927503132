.bas-currency-list {
  .bas-currency-row {
    margin: 5px 0;
    overflow: hidden;
    font-weight: 300;
    font-size: 30px;
    @media screen and (min-width: 992px) {
      font-size: 2.5vw;
    }

    &:first-child {
      margin-bottom: 13px;
    }

    &.main-bold {
      font-weight: bold;
      font-size: 35px;
      @media screen and (min-width: 992px) {
        font-size: 3.5vw;
      }
    }

    .currency-label {
      float: left;
    }

    .currency-amount {
      float: right;
    }
  }
}
