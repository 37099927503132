.lot-info-sec {
  padding: 0;
  @media screen and (min-width: 992px) {
    padding: 0 0 0 30px;
  }

  .lot-info-row {
    display: table;
    width: 100%;
    margin: 0 0 10px;
    padding: 0 0 10px;
    overflow: hidden;
    border-bottom: 1px solid #ddd;

    .lot-number {
      float: left;
      font-size: 50px;
      @media screen and (min-width: 992px) {
        font-size: 4vw;
      }
    }

    .current-winner {
      display: table-cell;
      font-size: 35px;
      text-align: right;
      vertical-align: middle;
      @media screen and (min-width: 992px) {
        font-size: 2.5vw;
      }
    }
  }
}
