.bid {
  position: relative;
  display: table;
  box-sizing: border-box;
  width: 100%;
  height: 45%;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 30px;

  .title {
    display: table-cell;
    width: 1em;
    padding-right: 1em;
    padding-left: 1em;
    font-weight: 700;
    font-size: 1em;
    line-height: 39px;
    letter-spacing: 15px;
    text-orientation: upright;
    vertical-align: middle;
    border-right: 2px solid #ddd;
    writing-mode: vertical-rl;

    &.new-bid {
      padding-right: 1.3em;
      padding-left: 1.3em;
      font-size: 0.7em;
      line-height: 46px;
      letter-spacing: 5px;
    }

    span {
      display: inline-block;
      text-transform: uppercase;
    }
  }

  .amount-bid {
    display: table-cell;
    height: 100%;
    font-size: 3.9em;
    vertical-align: middle;
  }
}
