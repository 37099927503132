.configuration-slide-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  box-sizing: border-box;
  width: 30vw;
  height: 100vh;
  padding: 10px 5px 10px 10px;
  overflow: auto;
  color: #fff;
  font-size: 16px;
  font-family: Arial, sans-serif;
  background: #282828;
  opacity: 0.9;

  .head {
    margin: 0 0 19px;
    padding: 9px 0 0;
    overflow: hidden;

    h4 {
      float: left;
      margin: 0;
      font-weight: bold;
      font-size: 18px;
    }

    span {
      padding-left: 0.3vw;
      font-size: 17px;
    }

    .close-btn {
      float: right;
      margin: -9px 0 0;
      padding: 0;
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      line-height: 1;
      background: none;
      border: 0;
      cursor: pointer;
    }
  }

  .main-widget {
    margin: 0 0 30px;

    h4 {
      margin: 0 0 20px;
      font-weight: 500;
      font-size: 18px;
    }

    &:last-child {
      margin: 0;
    }
  }

  .checkbox-list {
    ul {
      margin: -13px -5px -5px;
      padding: 0;
      overflow: hidden;
      list-style: none;

      li {
        float: left;
        width: 31%;
        padding: 5px;

        label {
          margin-bottom: 5px;
          font-weight: 700;
        }

        input {
          margin: 0 7px 0 0;
        }
      }
    }
  }

  .radiobox-list {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0 0 10px;

        label {
          cursor: pointer;
        }

        input {
          margin: 0 5px 0 0;

          &[disabled] {
            cursor: not-allowed;
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.gear-btn {
  position: absolute;
  bottom: 50px;
  left: 0;
  z-index: 99;
  display: block;
  width: 4vw;
  min-width: 60px;
  height: 9vh;
  background: #282828;
  border: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  opacity: 0.8;

  img {
    width: 40px;
    height: 40px;
  }
}

.settings-icon-holder {
  position: absolute;
  top: 0;
  left: 1vw;
  width: 60%;
  height: 100%;

  .gear-btn {
    left: -1vw;
    display: none;
  }

  &.slide-open {
    left: 0;
    width: 70%;

    .gear-btn {
      left: 30vw;
    }
  }

  &:hover {
    .gear-btn {
      display: block;
    }
  }
}
