/*
* TODO: Once the new dev and stage domains are allowed in cloud.typography.com
* revert this url back to
* https://cloud.typography.com/7846252/704082/css/fonts.css
*/
@import url('https://www.invaluable.com/styles/fonts/638382/AC410E23F58137969.css');

.sale-room-holder {
  display: block;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  padding: 15px;
  overflow: hidden;
  color: white;
  font-family: Arial, sans-serif !important;
  background: black;
  @media screen and (min-width: 992px) {
    display: flex;
    padding: 30px 30px 0;
  }

  .img-container {
    flex: 2;
    height: 400px;
    margin: 0 0 10px;
    @media screen and (min-width: 992px) {
      height: 600px;
      margin: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }
  }

  .lot-info-container {
    flex: 1;
  }

  &.full-lot-img {
    padding: 15px;
    @media screen and (min-width: 992px) {
      padding: 15px;
    }

    .img-container {
      height: 97vh;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top;
        @media screen and (min-width: 992px) {
          object-position: center;
        }
      }
    }

    .lot-info-container {
      display: none;
    }
  }

  .title-lot-number {
    position: absolute;
    top: 20px;
    left: 20px;
    display: none;

    h1 {
      margin: 0;
      font-size: 36px;
    }
  }

  &.img-with-text {
    @extend .full-lot-img;

    padding: 80px 20px 0;
    @media screen and (min-width: 992px) {
      padding: 70px 160px 0;
    }

    .img-container {
      height: 90vh;
    }

    .title-lot-number {
      display: block;

      h1 {
        font-weight: 500;
        font-family: Georgia, 'Times New Roman', serif;
      }
    }
  }

  &.light-theme {
    color: black;
    background: white;
  }
}
