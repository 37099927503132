.loader-holder {
  display: flex;
  align-items: center;
  justify-self: center;
  height: 100vh;
  background: black;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.load-spinner {
  width: 50px;
  height: 50px;
  margin: auto;
  border: solid 4px white;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
