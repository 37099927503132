.blinker-animation {
  -webkit-animation-name: blinker;
  -moz-animation-name: blinker;
  animation-name: blinker;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  &.white {
    color: #000;
    background-color: #fff;
  }

  &.gold {
    color: #fff;
    background-color: #c88704;
  }

  &.blue {
    color: #fff;
    background-color: #0a9fcd;
  }
}

@-moz-keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.board-main-holder {
  height: 100vh;

  .tote-board-main {
    width: 100%;
    height: 100%;
    color: #fff;
    font-weight: bold;
    font-size: 35px;
    font-family: Arial, sans-serif !important;
    background-color: #000;
    @media screen and (min-width: 1200px) {
      font-size: 45px;
    }
    @media screen and (min-width: 1420px) {
      font-size: 54px;
    }
  }
}
