.status {
  display: table;
  width: 100%;
  height: 25%;
  text-align: center;

  strong {
    display: table-cell;
    padding: 9px 0 0;
    font-size: 3.3em;
    text-transform: uppercase;
    vertical-align: middle;
  }
}
