.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  .lot-number {
    float: left;
    width: 33%;
    height: 1.4em;
    font-size: 2em;
    line-height: 1.4em;
    text-align: center;
    border-color: #ddd;
    border-style: solid;
    border-width: 2px;
  }

  .lot-bidder {
    @extend .lot-number;

    float: right;
  }
}
